body {
    /* https://css-tricks.com/snippets/css/system-font-stack/ & https://caniuse.com/#feat=font-family-system-ui */
    /*font-family: system-ui;*/
}

/* layout */
#root {
    /* center and limit content width */
    max-width: 1216px; /* limit: widescreen */
    margin-right: auto;
    margin-left: auto; /* equal left-right margin after max-width is exceeded */

    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 800px) {
    .box-center {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

/* flex-box (direction: row) with a border, margin, and padding */
.mj-box {
    /* https://www.paulirish.com/2012/box-sizing-border-box-ftw/
     * https://github.com/jgthms/bulma/blob/master/sass/base/minireset.sass */
    box-sizing: border-box;
    display: flex;

    border: 1px solid #DAE1E9;
    border-radius: 0.3rem;

    margin: 1rem 1rem 0 1rem;
    padding: 1rem;
}

.flex-row {
    display: flex;
    flex-direction: row; /* flexbox default */
}

.flex-column {
    display: flex;
    flex-direction: column;
}

/* alignment */
.center-all {
    align-items: center; /* flex: vertical when flex-direction: row (default), horizontal otherwise */
    justify-content: center; /* flex: horizontal */
    text-align: center; /* text: centered text */
}

.center-h {
    justify-content: center; /* flex: horizontal */
    text-align: center; /* text: centered text */
}

.align-start {
    align-items: flex-start; /* stretch is default, which stretches images in flex-row layout */
}

.flex-grow-1 {
    flex-grow: 1; /* by default, boxes in flex-row will only wrap content, this makes them take full with of remaining parent space */
}

.flex-order-1 {
    order: 1; /* default order is 0, so order: 1 will pull item to right */
}

.flex-wrap {
    flex-wrap: wrap; /* default: nowrap */
}

/* spacing */
/* note: try to use top and left margins whenever possible for simplicity and to avoid margin collapsing */
/* m: margin, p: padding  |  (optional) t, r, b, l: top, right, bottom, left  |  x: extra ..., s: small, m: medium, l: large */
/* xs: 0.25, s: 0.5, m: 1.0, l: 1.5, xl: 2.0 */
.m-m {
    margin: 1rem;
}

.m-t-l {
    margin-top: 1.5rem;
}

.m-t-m {
    margin-top: 1rem;
}

.m-t-s {
    margin-top: 0.5rem;
}

.m-t-xs {
    margin-top: 0.25rem;
}

.m-r-s {
    margin-right: 0.5rem;
}

.m-r-xs {
    margin-right: 0.25rem;
}

.m-l-xl {
    margin-left: 2rem;
}

.m-l-l {
    margin-left: 1.5rem;
}

.m-l-m {
    margin-left: 1rem;
}

.m-l-s {
    margin-left: 0.5rem;
}

.p-s {
    padding: 0.5rem;
}

/* sizing */
/* f: font, w: width, mw: max-width */
/* x: extra ..., s: small, m: medium, l: large */
.f-s {
    font-size: 0.8rem;
}

@media screen and (min-width: 800px) {
    .w-m {
        width: 750px;
    }

    .w-s {
        width: 500px;
    }
}

.mw-l {
    max-width: 1216px;
}

/* typography */
.faded {
    color: grey;
}

.wrap-text {
    word-wrap: break-word;
}

/* colors */
.bg-white {
    background-color: white;
}

/* animations */
@keyframes rotate360 {
    to {transform: rotate(360deg);}
}

/* ######### components ######### */

/* spinner */
.spinner::before {
    content: '';
    height: 1rem;
    width: 1rem;
    animation: rotate360 500ms infinite linear;
    border: 2px solid #209cee;
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-radius: 50%;
}

/* shared */
.stats-grid {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
}

/* shop */
.shop-grid {
    display: grid;
    /*grid-gap: 1rem;*/
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
}

.shop-grid a {
    color: #4a4a4a;
}